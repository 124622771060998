<template >

  <v-app id="inspire" >

    <v-navigation-drawer v-if="auth" app
                         v-model="drawer"
                         :mini-variant.sync="mini"
                         permanent
    >
      <v-list-item v-if="this.user" class="px-2">
        <v-list-item-avatar>
          <img src="./assets/image/user.jpg" >
        </v-list-item-avatar>

        <v-list-item-title>{{ this.user.first_name }} {{ this.user.last_name }}</v-list-item-title>

        <v-btn
            icon
            @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>




      <v-list-group
          :value="false"
          prepend-icon="mdi-file"
      >
        <template v-slot:activator>
          <v-list-item-title>AAR</v-list-item-title>
        </template>

        <v-list-item
            to="/aar_1"
            link
        >
          <v-list-item-content>
            <v-list-item-title
            >วงรอบที่1</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/aar_2"
            link
        >
          <v-list-item-content>
            <v-list-item-title>วงรอบที่2</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item
            to="/aar_3"
            link
        >
          <v-list-item-content>
            <v-list-item-title>การทบทวน</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list-group>

      <v-list-group
          :value="false"
          prepend-icon="mdi-file-arrow-left-right"
      >
        <template v-slot:activator>
          <v-list-item-title>PLC</v-list-item-title>
        </template>

        <v-list-item
            to="/plc"
            link
        >
          <v-list-item-content>
            <v-list-item-title
            >แลกเปลี่ยนเรียนรู้</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/plc2"
            link
        >
          <v-list-item-content>
            <v-list-item-title>แผนปฎิบัติการ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>


      <v-list-group
          :value="false"
          prepend-icon="mdi-calendar-expand-horizontal-outline"
      >
        <template v-slot:activator>
          <v-list-item-title>ID Plan</v-list-item-title>
        </template>

        <v-list-item
            to="/plan_1"
            link
        >
          <v-list-item-content>
            <v-list-item-title
            >ข้อมูลส่วนบุคคล</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/plan_history"
            link
        >
          <v-list-item-content>
            <v-list-item-title>ประวัติการเข้ารับการพัฒนาตนเอง</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/plan_requirement"
            link
        >
          <v-list-item-content>
            <v-list-item-title>ข้อมูลความต้องการ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/plan_detail"
            link
        >
          <v-list-item-content>
            <v-list-item-title>รายละเอียดแผน</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>


      <v-list-group
          :value="false"
          prepend-icon="mdi-account-outline"
      >
        <template v-slot:activator>
          <v-list-item-title>PORTFOLIO</v-list-item-title>
        </template>

        <v-list-item
            to="/profile"
            link
        >
          <v-list-item-content>
            <v-list-item-title
            >ประวัติส่วนตัว</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/cer"
            link
        >
          <v-list-item-content>
            <v-list-item-title>วุฒิการศึกษา</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/course"
            link
        >
          <v-list-item-content>
            <v-list-item-title>หลักสูตรอบรม</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            to="/work"
            link
        >
          <v-list-item-content>
            <v-list-item-title>ผลงาน</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item
            to="/work_community"
            link
        >
          <v-list-item-content>
            <v-list-item-title>ผลงานชุมชน</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>




      <v-list dense>
        <v-list-item
            link
            v-on:click="Logout()"
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ออกจากระบบ</v-list-item-title>
          </v-list-item-content>



        </v-list-item>
      </v-list>


      <div v-if="!mini" class="version_check" >
        V.1.0.2<br>
        build:0302202302
      </div>

    </v-navigation-drawer>
    <v-app-bar v-if="auth" class="top_bar_container" app>
      <div>-</div>
      <div></div>
      <div></div>
    </v-app-bar>
    <v-main>
      <div v-if="is_loading" class="loading" >
        <h1>
          <span class="let1">l</span>
          <span class="let2">o</span>
          <span class="let3">a</span>
          <span class="let4">d</span>
          <span class="let5">i</span>
          <span class="let6">n</span>
          <span class="let7">g</span>
        </h1>
      </div>

      <router-view/>
    </v-main>
    <v-footer v-if="auth" class="footer_custom" app>
      <div align="center" >
        COPYRIGHT © 2023 -
      </div>
    </v-footer>
  </v-app>
</template>

<script>

import {baseUrl} from "./const/api";


export default {
  name: 'App',

  components: {},
  data () {
    return {
      version_system:"v 1.2.0",
      version_api:"0",
      version_service:"0",
      baseUrl:baseUrl,
      auth: localStorage.getItem('user') ? true:false,
      user: JSON.parse(localStorage.getItem('user')),
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      mini: true,
      drawer: null,
      is_loading: false
    }
  },
  methods:{
    Logout(){
      localStorage.removeItem('user');
      window.location = "/login"
    },
    CheckLogin(){
      console.log(this.$router.currentRoute.path)
      const UserData = localStorage.getItem('user');
      if(!UserData && this.$router.currentRoute.path !== "/login"){
       window.location = "/login"
      }else{

        //console.log("Nologin")
      }
    },

  },
  mounted() {
    this.CheckLogin()

  },
  created() {
    //console.log(process.env)
  }
};
</script>
<style>
.footer_custom{
  justify-content: center;
}
.v-toolbar__content{
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}
.version_show{
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}
.v-application .blue.lighten-5{
  width: 300px !important;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child{
  margin-right: 10px !important;
}
.v-application ul, .v-application ol {
  padding-left: 55px !important;
}
.version_check{
  font-size: 0.9rem;
  padding: 1rem;
  color: #b3a6a6;
}
</style>
