<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>แผนพัฒนาตนเองของนักศึกษาครู</div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >


        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >



          <v-row>

            <v-col xl="6" lg="6" md="6" sm="12" >

              <v-text-field
                  v-model="departments"
                  :rules="[v => !!v || 'รายวิชากลุ่มเรียนรู้']"
                  label="รายวิชากลุ่มเรียนรู้"
                  required
              ></v-text-field>

            </v-col>
          </v-row>


          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="affiliation"
                  :rules="[v => !!v || 'กรุณากรอกสังกัด']"
                  label="สังกัด"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >

              <v-text-field
                  v-model="ministry"
                  :rules="[v => !!v || 'กรุณากรอกกระทรวง']"
                  label="กระทรวง"
                  required
              ></v-text-field>

            </v-col>
          </v-row>

          <hr>
          <h3>งานในหน้าที่ที่รับผิดชอบ</h3>

          <v-text-field
              v-model="work_amount"
              :rules="[v => !!v || 'จำนวนรายวิชาที่สอน']"
              label="จำนวนรายวิชาที่สอน"
              required
          ></v-text-field>

          <v-row class="mt-2 list_work"  v-for="index in parseInt(this.work_amount) " :key="index" >
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="subject_group[index-1]"
                  :rules="[v => !!v || 'กรุณากรอกกล่มสาระการเรียนรู้']"
                  label="กล่มสาระการเรียนรู้"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >

              <v-text-field
                  v-model="course[index-1]"
                  :rules="[v => !!v || 'กรุณากรอกรายวิชา']"
                  label="รายวิชา"
                  required
              ></v-text-field>

            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="class_[index-1]"
                  :rules="[v => !!v || 'กรุณากรอกชั้น']"
                  label="ชั้น"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >

              <v-text-field
                  v-model="periods[index-1]"
                  :rules="[v => !!v || 'กรุณากรอกจำนวนคาบ/สัปดาห์']"
                  label="จำนวนคาบ/สัปดาห์()"
                  required
              ></v-text-field>

            </v-col>
          </v-row><br><br>


          <v-textarea
              outlined
              label="งานที่ได้รับมอบหมายนอกเหนือจากการสอน"
              v-model="activity_list"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ความสามารถพิเศษ"
              v-model="talent"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <hr>
          <h3> ผลงาน  ที่เกิดจากการปฏิบัติหน้าที่ในปัจจุบัน (ในระยะ 1 ปี)</h3>

          <v-textarea
              class="ml-4"
              outlined
              label="ผลที่เกิดจากการจัดการเรียนรู้"
              v-model="result_learning"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-4"
              outlined
              label="ผลที่เกิดจากการพัฒนาวิชาการ"
              v-model="results_academic_development"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-4"
              outlined
              label="ผลที่เกิดกับผู้เรียน"
              v-model="outcomes_students"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-4"
              outlined
              label="ผลที่เกิดกับสถานศึกษา"
              v-model="outcomes_school"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>



          <v-textarea
              class="ml-4"
              outlined
              label="ผลที่เกิดกับชุมชน"
              v-model="community_effects"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
              บันทึก
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
              ยกเลิก
            </v-btn><br>
            <br>
            <font v-if="check" style="font-size: 1rem;color: #fa0505;" >กรุณากรอกข้อมููลให้ครบ</font><br>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData, getDepartment, postPlan} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,
    check: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'แผนพัฒนาตนเองของนักศึกษาครู',
        disabled: true,
        href: '/aar_2',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    first_name:"",
    last_name:"",
    sex:"",
    course_select:1,
    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    school:"",
    affiliation:"",
    ministry:"",
    work_amount:0,
    subject_group:[],
    course:[],
    class_:[],
    periods:[],
    activity_list:"",
    talent:"",
    result_learning:"",
    results_academic_development:"",
    outcomes_students:"",
    outcomes_school:"",
    educational_institutions:"",
    community_effects:"",
    users:JSON.parse(localStorage.getItem('user')),


    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
    department_select:1,
    department_list:[],
    departments:"",
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

 async mounted () {
    this.initialize()
   await this.GetDepartment()
   await this.GetData()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const class_ = [];
        const course = [];
        const periods = [];
        const subject_group = [];

        switch (status){
          case 200:



            this.affiliation = data.plan.affiliation;
            this.ministry = data.plan.ministry;
            this.activity_list = data.plan.activity_list;
            this.talent = data.plan.talent;
            this.result_learning = data.plan.result_learning;
            this.results_academic_development = data.plan.results_academic_development;
            this.outcomes_students = data.plan.outcomes_students;
            this.outcomes_school = data.plan.outcomes_school;
            this.community_effects = data.plan.community_effects;
            this.departments = data.plan.departments;




            data.work_list.forEach((result)=>{
              console.log(result)
              class_.push(result.class_)
              course.push(result.course)
              periods.push(result.periods)
              subject_group.push(result.subject_group)
            })

            this.work_amount = course.length;
            this.class_ = class_;
            this.course = course;
            this.periods = periods;
            this.subject_group = subject_group;

            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("affiliation",this.affiliation);
      formData.append("department",this.department_select);
      formData.append("ministry",this.ministry);
      formData.append("activity_list",this.activity_list);
      formData.append("talent",this.talent);
      formData.append("result_learning",this.result_learning);
      formData.append("results_academic_development",this.results_academic_development);
      formData.append("outcomes_students",this.outcomes_students);
      formData.append("outcomes_school",this.outcomes_school);
      formData.append("community_effects",this.community_effects);
      formData.append("subject_group","-"+this.subject_group);
      formData.append("course","-"+this.course);
      formData.append("class_","-"+this.class_);
      formData.append("periods","-"+this.periods);
      formData.append("departments",this.departments);



      await postPlan(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
             await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.check = false;
        this.Update();
      }else {
        this.check = true;
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async GetDepartment(){
      await getDepartment((response)=>{

        const {status, data} = ResponseData(response)

        const department = [];

        switch (status){
          case 200:
            console.log(data)


            data.forEach((result)=>{
              department.push({
                "id":result.id,
                "label":result.name,
              })
            });

            this.department_list = department;

            break;
          default:


            break;
        }

      });
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.list_work{
  margin-top: 1rem !important;
  border: 1px solid #d0d0d0;
}
</style>
