<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>แลกเปลี่ยนเรียนรู้ร่วมกันในรูปแบบชุมชนแห่งการเรียนรู้ (PLC) พัฒนา : Creative Discussion (แนวทางปฏิบัติและพัฒนาตนเอง)</div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-textarea
              outlined
              label="1. หลักการและเหตุผล"
              v-model="principle_reason"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="2.วัตถุประสงค์"
              v-model="objective"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="3. วิธีการดำเนินงาน "
              v-model="method_operation"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-8"
              outlined
              label="3.1 แนวทางการปฏิบัติกิจกรรมการสร้างชุมชนการเรียนรู้ทางวิชาชีพ (PLC) ดังนี้"
              v-model="activity_guidelines"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              class="ml-8"
              outlined
              label="3.2 กระบวนการของ PLC"
              v-model="process_of_plc"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-8"
              outlined
              label="3.3 บทบาทหน้าที่ของสมาชิกกลุ่มตามกระบวนการ PLC"
              v-model="role_of_plc"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              class="ml-8"
              outlined
              label="3.4. วัน เวลา สถานที่ ในการดำเนินงาน"
              v-model="date_time_place"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-8"
              outlined
              label="3.5 สรุปผลการดำเนินงาน"
              v-model="summary"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <div  class="ml-8" >
            <v-textarea
                class="ml-8"
                outlined
                label="3.5.1 ประเด็นด้านผู้เรียน"
                v-model="student_issues"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>

            <v-textarea
                class="ml-8"
                outlined
                label="3.5.2 ประเด็นด้านกิจกรรม"
                v-model="activity_issues"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>


            <v-textarea
                class="ml-8"
                outlined
                label="3.5.3 ประเด็นด้านครู"
                v-model="teacher_issues"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>

            <v-textarea
                class="ml-8"
                outlined
                label="3.5.4 ประเด็นสื่อการสอน"
                v-model="teaching_media_issues"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>


            <v-textarea
                class="ml-8"
                outlined
                label="3.5.5 ประเด็นด้านบรรยากาศ"
                v-model="environment_issues"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>

          </div>

          <v-textarea
              class="ml-8"
              outlined
              label="3.6 อภิปรายผลการดำเนินงาน "
              v-model="performance_discussion"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <div class="ml-8" >

            <v-textarea
                class="ml-8"
                outlined
                label="3.6.1 ผลลัพธ์ที่เกิดจากกระบวนการ ดังนี้"
                v-model="result_issues"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>

            <v-textarea
                class="ml-8"
                outlined
                label="3.6.2 ผลลัพธ์ที่เกิดกับผู้เรียน / ครู / สมาชิกที่เข้าร่วมเครือข่าย PLC"
                v-model="result_student_teacher_plc"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>

            <v-textarea
                class="ml-8"
                outlined
                label="3.6.3 คุณค่าที่เกิดต่อวงการศึกษา"
                v-model="result_student_education"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>

            <v-textarea
                class="ml-8"
                outlined
                label="3.6.3 คุณค่าที่เกิดต่อวงการศึกษา"
                v-model="value_in_education"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            ></v-textarea>

          </div>

          <v-textarea
              class="ml-8"
              outlined
              label="3.7 ผลที่เกิดจากการดำเนินงาน "
              v-model="result_operation"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-8"
              outlined
              label="3.8 ร่องรอย/หลักฐาน"
              v-model="trace_evidence"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              class="ml-8"
              outlined
              label="3.9 บทเรียนที่ได้จากการดำเนินงาน"
              v-model="learned_Implementation"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              class="ml-8"
              outlined
              label="3.10 สิ่งที่จะดำเนินการต่อไป"
              v-model="what_to_do_next"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              class="ml-8"
              outlined
              label="3.11 ปัญหา /อุปสรรค"
              v-model="problems_obstacles"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              class="ml-8"
              outlined
              label="3.12 ข้อเสนอแนะ"
              v-model="suggestion"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>






          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
              อัพเดท
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
             ล้างค่า
            </v-btn>
            <br>
            <br>
            <font v-if="check" style="font-size: 1rem;color: #fa0505;" >กรุณากรอกข้อมููลให้ครบ</font><br>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData, postPLC} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',
    check:false,


    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'แลกเปลี่ยนเรียนรู้ร่วมกันในรูปแบบชุมชนแห่งการเรียนรู้ (PLC) พัฒนา : Creative Discussion (แนวทางปฏิบัติและพัฒนาตนเอง)',
        disabled: true,
        href: '/plc',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    name:"",
    school:"",
    amphur:"",
    province:"",
    class_detail:"",
    student_amount:"",
    course_select:1,
    people_amount:0,

    principle_reason:"",
    objective:"",
    method_operation:"",
    activity_guidelines:"",
    process_of_plc:"",
    role_of_plc:"",
    date_time_place:"",
    summary:"",
    student_issues:"",
    activity_issues:"",
    teacher_issues:"",
    teaching_media_issues:"",
    environment_issues:"",
    performance_discussion:"",
    result_issues:"",
    result_student_teacher_plc:"",
    result_student_education:"",
    value_in_education:"",
    result_operation:"",
    trace_evidence:"",
    learned_Implementation:"",
    what_to_do_next:"",
    problems_obstacles:"",
    suggestion:"",



    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

 async mounted () {
    this.initialize()
   await this.GetData()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.check = false;
        this.Update();
      }else {
        this.check = true;
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)


        switch (status){
          case 200:
            console.log(data.aar_1)


            this.principle_reason = data.plc.principle_reason;
            this.objective = data.plc.objective;
            this.method_operation = data.plc.method_operation;
            this.activity_guidelines = data.plc.activity_guidelines;
            this.process_of_plc = data.plc.process_of_plc;
            this.role_of_plc = data.plc.role_of_plc;
            this.date_time_place = data.plc.date_time_place;
            this.summary = data.plc.summary;
            this.student_issues = data.plc.student_issues;
            this.activity_issues = data.plc.activity_issues;
            this.teacher_issues = data.plc.teacher_issues;
            this.teaching_media_issues = data.plc.teaching_media_issues;
            this.environment_issues = data.plc.environment_issues;
            this.performance_discussion = data.plc.performance_discussion;
            this.result_issues = data.plc.result_issues;
            this.result_student_teacher_plc = data.plc.result_student_teacher_plc;
            this.result_student_education = data.plc.result_student_education;
            this.value_in_education = data.plc.value_in_education;
            this.result_operation = data.plc.result_operation;
            this.trace_evidence = data.plc.trace_evidence;
            this.learned_Implementation = data.plc.learned_Implementation;
            this.what_to_do_next = data.plc.what_to_do_next;
            this.problems_obstacles = data.plc.problems_obstacles;
            this.suggestion = data.plc.suggestion;



            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("principle_reason",this.principle_reason);
      formData.append("objective",this.objective);
      formData.append("method_operation",this.method_operation);
      formData.append("activity_guidelines",this.activity_guidelines);
      formData.append("process_of_plc",this.process_of_plc);
      formData.append("role_of_plc",this.role_of_plc);
      formData.append("date_time_place",this.date_time_place);
      formData.append("summary",this.summary);
      formData.append("student_issues",this.student_issues);
      formData.append("activity_issues",this.activity_issues);
      formData.append("teacher_issues",this.teacher_issues);
      formData.append("teaching_media_issues",this.teaching_media_issues);
      formData.append("environment_issues",this.environment_issues);
      formData.append("performance_discussion",this.performance_discussion);
      formData.append("result_issues",this.result_issues);
      formData.append("result_student_teacher_plc",this.result_student_teacher_plc);
      formData.append("result_student_education",this.result_student_education);
      formData.append("value_in_education",this.value_in_education);
      formData.append("result_operation",this.result_operation);
      formData.append("trace_evidence",this.trace_evidence);
      formData.append("learned_Implementation",this.learned_Implementation);
      formData.append("problems_obstacles",this.problems_obstacles);
      formData.append("what_to_do_next",this.what_to_do_next);
      formData.append("suggestion",this.suggestion);


      await postPLC(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
