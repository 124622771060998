<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>การทบทวนหลังการปฏิบัติงาน </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="work_name"
              :rules="[v => !!v || 'กรุณากรอกชื่องาน']"
              label="ชื่องาน"
              required
          ></v-text-field>


          <h3 class="mb-5" >กระบวนการจัดการความรู้มี 7 กิจกรรม </h3>



          <v-textarea
              outlined
              label="1. การบ่งชี้ความรู้ "
              v-model="indication_of_knowledge"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="2. การสร้างและแสวงหา ความรู้  "
              v-model="creating_knowledge"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="3. การจัดความรู้ให้เป็นระบบ "
              v-model="knowledge_into_system"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="4. การประมวลและกลั่นกรอง ความรู้  "
              v-model="filtering_knowledge"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="5. การเข้าถึงความรู้"
              v-model="access_knowledge"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="6. การแบ่งปันแลกเปลี่ยนความรู้ "
              v-model="knowledge_sharing"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="7. การเรียนรู้  "
              v-model="learning"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <hr>


          <v-textarea
              outlined
              label="เป้าหมายของงาน "
              v-model="goal_of_event"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ผลการปฏิบัติ/ผลลัพธ์ที่เกิดขึ้นจริง (โดยสรุป) "
              v-model="summary"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="งาน/ขั้นตอนที่ทำได้ดี "
              v-model="good_job_step"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="งาน/ขั้นตอนที่ทำได้ไม่ดี"
              v-model="poor_job_step"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="อุปสรรค/ข้อจำกัด/ข้อขัดข้อง ที่พบในระหว่างการปฏิบัติงาน "
              v-model="obstacles_limitations"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ประเด็นที่ได้เรียนรู้"
              v-model="learned_issues"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ข้อปฏิบัติในการทำงานครั้งต่อไป "
              v-model="guidelines_next_job"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>




          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
              บันทึก
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
             ยกเลิก
            </v-btn>
            <br>
            <br>
            <font v-if="check" style="font-size: 1rem;color: #fa0505;" >กรุณากรอกข้อมููลให้ครบ</font><br>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData,postAAR3,} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การทบทวนหลังการปฏิบัติงาน ',
        disabled: true,
        href: '/aar_3',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    name:"",
    school:"",
    amphur:"",
    province:"",
    class_detail:"",
    student_amount:"",
    course_select:1,
    people_amount:0,

    indication_of_knowledge:"",
    creating_knowledge:"",
    knowledge_into_system:"",
    filtering_knowledge:"",
    access_knowledge:"",
    knowledge_sharing:"",
    learning:"",
    work_name:"",
    goal_of_event:"",
    summary:"",
    good_job_step:"",
    poor_job_step:"",
    obstacles_limitations:"",
    learned_issues:"",
    guidelines_next_job:"",
    check:false,

    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created () {
    this.initialize()
    this.GetData()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const name_visitor = [];
        const type_visitor = [];

        switch (status){
          case 200:


            this.work_name = data.aar_3.work_name;
            this.indication_of_knowledge = data.aar_3.indication_of_knowledge;
            this.creating_knowledge = data.aar_3.creating_knowledge;
            this.knowledge_into_system = data.aar_3.knowledge_into_system;
            this.filtering_knowledge = data.aar_3.filtering_knowledge;
            this.access_knowledge = data.aar_3.access_knowledge;
            this.knowledge_sharing = data.aar_3.knowledge_sharing;
            this.goal_of_event = data.aar_3.goal_of_event;
            this.summary = data.aar_3.summary;
            this.good_job_step = data.aar_3.good_job_step;
            this.poor_job_step = data.aar_3.poor_job_step;
            this.obstacles_limitations = data.aar_3.obstacles_limitations;
            this.learned_issues = data.aar_3.learned_issues;
            this.guidelines_next_job = data.aar_3.guidelines_next_job;
            this.learning = data.aar_3.learning;





            data.visitor_aar2.forEach((result)=>{
              console.log(result)
              name_visitor.push(result.name)
              type_visitor.push(result.type)
            })

            this.people_amount = name_visitor.length;
            this.name_visitor = name_visitor;
            this.type_visitor = type_visitor;

            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.check = false;
        this.Update();
      }else {
        this.check = true;
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("work_name",this.work_name);
      formData.append("indication_of_knowledge",this.indication_of_knowledge);
      formData.append("creating_knowledge",this.creating_knowledge);
      formData.append("knowledge_into_system",this.knowledge_into_system);
      formData.append("filtering_knowledge",this.filtering_knowledge);
      formData.append("access_knowledge",this.access_knowledge);
      formData.append("knowledge_sharing",this.knowledge_sharing);
      formData.append("learning",this.learning);
      formData.append("goal_of_event",this.goal_of_event);
      formData.append("summary",this.summary);
      formData.append("good_job_step",this.good_job_step);
      formData.append("poor_job_step",this.poor_job_step);
      formData.append("obstacles_limitations",this.obstacles_limitations);
      formData.append("learned_issues",this.learned_issues);
      formData.append("guidelines_next_job",this.guidelines_next_job);


      await postAAR3(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              this.GetData();
            });


            break;
          default:



            break;
        }

      })

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
