<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>แผนปฏิบัติการ </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="name"
              :rules="[v => !!v || 'ชื่อ-นามสกุล(เจ้าของแผน)']"
              label="ชื่อ-นามสกุล(เจ้าของแผน)"
              required
          ></v-text-field>


          <v-text-field
              v-model="position"
              :rules="[v => !!v || 'วิทยฐานะ']"
              label="วิทยฐานะ"
              required
          ></v-text-field>

          <v-text-field
              v-model="school"
              :rules="[v => !!v || 'กรุณากรอกโรงเรียน']"
              label="โรงเรียน"
              required
          ></v-text-field>

          <v-text-field
              v-model="affiliation"
              :rules="[v => !!v || 'กรุณากรอกสังกัด']"
              label="สังกัด"
              required
          ></v-text-field>

          <v-text-field
              v-model="province"
              :rules="[v => !!v || 'กรุณากรอกจังหวัด']"
              label="จังหวัด"
              required
          ></v-text-field>





          <v-text-field
              v-model="course"
              :rules="[v => !!v || 'รายวิชาดำเนินการ']"
              label="รายวิชาดำเนินการ"
              required
          ></v-text-field>


          <v-text-field
              v-model="department"
              :rules="[v => !!v || 'กลุ่มการเรียนรู้']"
              label="กลุ่มการเรียนรู้"
              required
          ></v-text-field>


          <v-text-field
              v-model="class_room_name"
              :rules="[v => !!v || 'กรุณากรอกนักเรียนชั้น/ห้อง']"
              label="นักเรียนชั้น/ห้อง"
              required
          ></v-text-field>

          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="student_man_amount"
                  :rules="[v => !!v || 'กรึณากรอกจำนวนนักเรียนชาย']"
                  label="จำนวนนักเรียนชาย"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="student_woman_amount"
                  :rules="[v => !!v || 'กรึณากรอกจำนวนนักเรียนหญิง']"
                  label="จำนวนนักเรียนหญิง"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <hr>
          <h3>สมาชิกทีม PLT ประเภทเพื่อนครู (Buddy Teachers : BT) </h3>

          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="friend_teacher"
                  :rules="[v => !!v || 'กรุณากรอกเพื่อนครู']"
                  label="เพื่อนครู"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="friend_department"
                  :rules="[v => !!v || 'กรุณากรอกวิชาเอก']"
                  label="วิชาเอก"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="friend_email"
                  :rules="[v => !!v || 'กรุณากรอกอีเมล']"
                  label="อีเมล"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <hr>

          <h3>สมาชิกทีม PLT ประเภทผู้บริหาร (School Administrators :SA) </h3>

          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="school_director"
                  :rules="[v => !!v || 'กรุณากรอกผู้อำนวยการโรงเรียน']"
                  label="ผู้อำนวยการโรงเรียน"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="director_academic"
                  :rules="[v => !!v || 'กรุณากรอกวิทยฐานะ']"
                  label="วิทยฐานะ"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="director_department"
                  :rules="[v => !!v || 'กรุณากรอกวิชาเอก']"
                  label="วิชาเอก"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="school_deputy_director"
                  :rules="[v => !!v || 'กรุณากรอกรองผู้อำนวยการโรงเรียน']"
                  label="รองผู้อำนวยการโรงเรียน"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="deputy_director_academic"
                  :rules="[v => !!v || 'กรุณากรอกวิทยฐานะ']"
                  label="วิทยฐานะ"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="deputy_director_department"
                  :rules="[v => !!v || 'กรุณากรอกวิชาเอก']"
                  label="วิชาเอก"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="school_group_head"
                  :rules="[v => !!v || 'กรุณากรอกหัวหน้ากลุ่มสาระ']"
                  label="หัวหน้ากลุ่มสาระ"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="group_head_academic"
                  :rules="[v => !!v || 'กรุณากรอกวิทยฐานะ']"
                  label="วิทยฐานะ"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="group_head_department"
                  :rules="[v => !!v || 'กรุณากรอกวิชาเอก']"
                  label="วิชาเอก"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <hr>

          <h3>สมาชิกทีม PLT ประเภทผู้ทรงคุณวุฒิทางการศึกษา (Senior Educatior :SE) </h3>

          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="qualified_person"
                  :rules="[v => !!v || 'กรุณากรอกชื่อ-นามสกุล']"
                  label="ชื่อ-นามสกุล"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" >
              <v-text-field
                  v-model="qualified_person_academic"
                  :rules="[v => !!v || 'วิทยฐานะ/ตำแหน่งวิชาการ']"
                  label="วิทยฐานะ/ตำแหน่งวิชาการ"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <hr>

          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
               บันทึก
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
             ล้างค่า
            </v-btn>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData, getDepartment,postPLC2} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'แผนปฏิบัติการชุมชนแห่งการเรียนรู้ทางวิชาชีพ (PLC Action Plan)\n' +
            'แผนการดำเนินงาน ภาคเรียนที่ 1 ปีการศึกษา 2566\n',
        disabled: true,
        href: '/plc2',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    name:"",
    position:"",
    school:"",
    amphur:"",
    province:"",
    affiliation:"",
    course_select:"",

    course:"",

    department_select:"",
    class_room_name:"",
    student_amount:"",
    student_man_amount:"",
    student_woman_amount:"",

    friend_teacher:"",
    friend_department:"",
    friend_email:"",

    school_director:"",
    director_academic:"",
    director_department:"",

    school_deputy_director:"",
    deputy_director_academic:"",
    deputy_director_department:"",

    school_group_head:"",
    group_head_academic:"",
    group_head_department:"",
    qualified_person:"",
    qualified_person_academic:"",




    cours_list:[
      {
        "label":"ภาษาอังกฤษอ่านวิเคราะห์",
        "id":1
      },
      {
        "label":"คณิตคิดไว",
        "id":2
      },
      {
        "label":"ภาษาไทยสำหรับสื่อสาร",
        "id":3
      }
    ],

    department_list:[
      {
        "label":"ภาษาอังกฤษ",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คณิตศาสตร์",
        "id":3
      }
    ],

    people_select:[],
    people_type_select:[],


    time: null,
    menu2: false,
    modal2: false,
    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

 async created () {
    this.initialize()
   await this.GetData()
   await this.GetDepartment()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async GetDepartment(){
      await getDepartment((response)=>{

        const {status, data} = ResponseData(response)

        const department = [];

        switch (status){
          case 200:
            console.log(data)


            data.forEach((result)=>{
              department.push({
                "id":result.id,
                "label":result.name,
              })
            });

            this.department_list = department;

            break;
          default:


            break;
        }

      });
    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)


        switch (status){
          case 200:
            console.log(data.aar_1)


            this.name = data.plc2.name;
            this.position = data.plc2.position;
            this.school = data.plc2.school;
            this.affiliation = data.plc2.affiliation;
            this.province = data.plc2.province;
            this.course = data.plc2.course;
            this.department = data.plc2.department;
            this.class_room_name = data.plc2.class_room_name;
            this.student_man_amount = data.plc2.student_man_amount;
            this.student_woman_amount = data.plc2.student_woman_amount;
            this.friend_teacher = data.plc2.friend_teacher;
            this.friend_department = data.plc2.friend_department;
            this.friend_email = data.plc2.friend_email;
            this.school_director = data.plc2.school_director;
            this.director_academic = data.plc2.director_academic;
            this.director_department = data.plc2.director_department;
            this.school_deputy_director = data.plc2.school_deputy_director;
            this.deputy_director_academic = data.plc2.deputy_director_academic;
            this.deputy_director_department = data.plc2.deputy_director_department;
            this.school_group_head = data.plc2.school_group_head;
            this.group_head_academic = data.plc2.group_head_academic;
            this.group_head_department = data.plc2.group_head_department;
            this.qualified_person = data.plc2.qualified_person;
            this.qualified_person_academic = data.plc2.qualified_person_academic;




            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("name",this.name);
      formData.append("position",this.position);
      formData.append("class_room_name",this.class_room_name);
      formData.append("school",this.school);
      formData.append("affiliation",this.affiliation);
      formData.append("province",this.province);
      formData.append("course",this.course);
      formData.append("department",this.department);
      formData.append("student_man_amount",this.student_man_amount);
      formData.append("student_woman_amount",this.student_woman_amount);
      formData.append("friend_teacher",this.friend_teacher);
      formData.append("friend_department",this.friend_department);
      formData.append("friend_email",this.friend_email);
      formData.append("school_director",this.school_director);
      formData.append("director_academic",this.director_academic);
      formData.append("director_department",this.director_department);
      formData.append("school_deputy_director",this.school_deputy_director);
      formData.append("deputy_director_academic",this.deputy_director_academic);
      formData.append("deputy_director_department",this.deputy_director_department);
      formData.append("school_group_head",this.school_group_head);
      formData.append("group_head_academic",this.group_head_academic);
      formData.append("group_head_department",this.group_head_department);
      formData.append("qualified_person",this.qualified_person);
      formData.append("qualified_person_academic",this.qualified_person_academic);




      await postPLC2(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
