import { render, staticRenderFns } from "./plan_1.vue?vue&type=template&id=f4a58d8e&scoped=true&"
import script from "./plan_1.vue?vue&type=script&lang=js&"
export * from "./plan_1.vue?vue&type=script&lang=js&"
import style0 from "./plan_1.vue?vue&type=style&index=0&id=f4a58d8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4a58d8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VBtn,VCol,VForm,VRow,VTextField,VTextarea})
