<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>การสะท้อนการเรียนรู้ วงรอบที่ 2 </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-row>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="วันที่สะท้อนการจัดกิจกรรม"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="[v => !!v || 'กรุณากรอกวันที่สะท้อนการจัดกิจกรรม']"
                      required
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    :active-picker.sync="activePicker"

                    @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="time"
                      label="เวลาที่สะท้อนกิจกรรม"
                      prepend-icon="mdi-clock-time-four-outline"
                      :rules="[v => !!v || 'กรุณากรอกเวลาที่สะท้อนกิจกรรม']"
                      required
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    format="24hr"
                    no-title
                    @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-text-field
              v-model="class_name"
              :rules="[v => !!v || 'นักเรียนชั้น']"
              label="นักเรียนชั้น"
              required
          ></v-text-field>

          <v-text-field
              v-model="amount_student"
              :rules="[v => !!v || 'จำนวน']"
              label="จำนวนนักเรียน"
              required
          ></v-text-field>

          <v-text-field
              v-model="departments"
              :rules="[v => !!v || 'รายวิชากลุ่มเรียนรู้']"
              label="รายวิชากลุ่มเรียนรู้"
              required
          ></v-text-field>

          <v-text-field
              v-model="people_amount"
              :rules="[v => !!v || 'จำนวน']"
              label="จำนวนผู้เข้าร่วม"
              required
          ></v-text-field>

          <div>
            <v-row v-if="people_amount !== 0" class="table_container" >
              <table class="custom_table" border="1" >
                <tr>
                  <th>ลำดับ</th>
                  <th>ชื่อ-สกุล</th>
                  <th>ผู้ร่วม AAR</th>
                </tr>
                <tr v-for="index in parseInt(this.people_amount) " :key="index" >
                  <td>
                    {{ index }}
                  </td>
                  <td>
                    <v-text-field
                        v-model="name_visitor[index-1]"
                        :rules="[v => !!v || 'ชื่อ-นามสกุล']"
                        label="ชื่อ-นามสกุล"
                        required
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="type_visitor[index-1]"
                        :rules="[v => !!v || 'ประเภทผู้เข้าร่วม']"
                        label="ประเภทผู้เข้าร่วม"
                        required
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-row>
          </div>

          <h3 class="mb-5" >ประเด็นนำสะท้อนกิจกรรมการเรียนรู้ก่อนนำไปใช้สอน</h3>


          <v-textarea
              outlined
              label="1. สิ่งที่ครูผู้สอนทำได้ดีและควรรักษาไว้ให้มีต่อไป "
              v-model="well_done"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="2. สิ่งที่เป็นปัญหาและอุปสรรคที่ทำให้การจัดกิจกรรมการเรียนรู้ในครั้งนี้ไม่เป็นไปตามเป้าหมาย"
              v-model="not_on_target"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="3. สิ่งที่ครูต้องปรับให้ดีขึ้นเพื่อพัฒนาการเรียนรู้ของนักเรียนมีประเด็นใดบ้างและจะทำอย่างไร"
              v-model="improve"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="4. นักเรียนบรรลุวัตถุประสงค์การเรียนรู้ในครั้งนี้จำนวนกี่คน"
              v-model="student_achieve"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="5. วิธีการแก้ปัญหาที่นำมาใช้ในการจัดกิจกรรมการเรียนรู้เกิดผลอย่างไร"
              v-model="howto_fix"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-text-field
              v-model="total_hour"
              :rules="[value => (value !== '' && value > 0) || 'กรอกชั่วโมงเป็นตัวเลขเท่านั้น']"
              label="สรุปเวลา/ชั่วโมง (กรอกแค่ตัวเลข)"
              required
          ></v-text-field>

          <hr>




          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
              บันทึก
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
             ยกเลิก
            </v-btn>
            <br>
            <br>
            <font v-if="check" style="font-size: 1rem;color: #fa0505;" >กรุณากรอกข้อมููลให้ครบ</font><br>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData, getDepartment, postAAR2} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 2',
        disabled: true,
        href: '/aar_2',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    name:"",
    school:"",
    amphur:"",
    province:"",
    class_detail:"",
    student_amount:"",
    department_select:1,
    people_amount:0,
    departments:"",
    total_hour:"",



    department_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    well_done:"",
    not_on_target:"",
    improve:"",
    student_achieve:"",
    howto_fix:"",
    class_name:"",
    amount_student:"",

    time: null,
    menu2: false,
    modal2: false,
    users:JSON.parse(localStorage.getItem('user')),
    name_visitor:[],
    type_visitor:[],
    check:false,
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  async mounted () {
    this.initialize()
   await this.GetDepartment()
   await this.GetData()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.check = false;
        this.Update();
      }else {
        this.check = true;
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },

    async GetDepartment(){
      await getDepartment((response)=>{

        const {status, data} = ResponseData(response)

        const department = [];

        switch (status){
          case 200:
            console.log(data)


            data.forEach((result)=>{
              department.push({
                "id":result.id,
                "label":result.name,
              })
            });

            this.department_list = department;

            break;
          default:


            break;
        }

      });
    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const name_visitor = [];
        const type_visitor = [];

        switch (status){
          case 200:


            this.date = data.aar_2.date_activity;
            this.time = data.aar_2.time_activity;
            this.class_name = data.aar_2.class_name;
            this.amount_student = data.aar_2.amount_student;
            this.department_select = data.aar_2.department;
            this.well_done = data.aar_2.well_done;
            this.not_on_target = data.aar_2.not_on_target;
            this.improve = data.aar_2.improve;
            this.student_achieve = data.aar_2.student_achieve;
            this.howto_fix = data.aar_2.howto_fix;
            this.total_hour = data.aar_2.total_hour;
            this.departments = data.aar_2.departments;




            data.visitor_aar2.forEach((result)=>{
              console.log(result)
              name_visitor.push(result.name)
              type_visitor.push(result.type)
            })

            this.people_amount = name_visitor.length;
            this.name_visitor = name_visitor;
            this.type_visitor = type_visitor;

            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("date_activity",this.date);
      formData.append("time_activity",this.time);
      formData.append("class_name",this.class_name);
      formData.append("amount_student",this.amount_student);
      formData.append("well_done",this.well_done);
      formData.append("not_on_target",this.not_on_target);
      formData.append("improve",this.improve);
      formData.append("department",this.department_select);
      formData.append("howto_fix",this.howto_fix);
      formData.append("departments",this.departments);
      formData.append("student_achieve",this.student_achieve);
      formData.append("total_hour",this.total_hour);
      formData.append("name_visitor","-"+this.name_visitor);
      formData.append("type_visitor","-"+this.type_visitor);

      await postAAR2(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
             await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    }

  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
